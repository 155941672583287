/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.3.1): emojiarea.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

import { jQuery as $, makeArray, typeCheckConfig } from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'emojiarea'
const VERSION = '4.3.1'
const DATA_KEY = 'bs.emojiarea'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  shortcodes: false,
  maxLength: 400,
  autogrow: false,
  wordcount: false,
  path: 'img/emojis',
  icons: [
    { code: ':angel:', image: 'angel.gif' },
    { code: ':angry:', image: 'angry.gif' },
    { code: ':bigsmile:', image: 'bigsmile.gif' },
    { code: ':blush:', image: 'blush.gif' },
    { code: ':clapping:', image: 'clapping.gif' },
    { code: ':cool:', image: 'cool.gif' },
    { code: ':crying:', image: 'crying.gif' },
    { code: ':devil:', image: 'devil.gif' },
    { code: ':dull:', image: 'dull.gif' },
    { code: ':envy:', image: 'envy.gif' },
    { code: ':evilgrin:', image: 'evilgrin.gif' },
    { code: ':giggle:', image: 'giggle.gif' },
    { code: ':happy:', image: 'happy.gif' },
    { code: ':hi:', image: 'hi.gif' },
    { code: ':inlove:', image: 'inlove.gif' },
    { code: ':itwasntme:', image: 'itwasntme.gif' },
    { code: ':kiss:', image: 'kiss.gif' },
    { code: ':lipssealed:', image: 'lipssealed.gif' },
    { code: ':makeup:', image: 'makeup.gif' },
    { code: ':mmm:', image: 'mmm.gif' },
    { code: ':nerd:', image: 'nerd.gif' },
    { code: ':nod:', image: 'nod.gif' },
    { code: ':party:', image: 'party.gif' },
    { code: ':rofl:', image: 'rofl.gif' },
    { code: ':sadsmile:', image: 'sadsmile.gif' },
    { code: ':shake:', image: 'shake.gif' },
    { code: ':sleepy:', image: 'sleepy.gif' },
    { code: ':smile:', image: 'smile.gif' },
    { code: ':smirk:', image: 'smirk.gif' },
    { code: ':sweating:', image: 'sweating.gif' },
    { code: ':talking:', image: 'talking.gif' },
    { code: ':thinking:', image: 'thinking.gif' },
    { code: ':tongueout:', image: 'tongueout.gif' },
    { code: ':whew:', image: 'whew.gif' },
    { code: ':wink:', image: 'wink.gif' },
    { code: ':wondering:', image: 'wondering.gif' },
    { code: ':worried:', image: 'worried.gif' },
    { code: ':yawn:', image: 'yawn.gif' }
  ]
}

const DefaultType = {
  shortcodes: 'boolean',
  maxLength: 'number',
  path: 'string',
  icons: 'array'
}

const Event = {
  READY: `ready${EVENT_KEY}`,
  CLICK: `click${EVENT_KEY}`,
  FOCUS: `focus${EVENT_KEY}`,
  BLUR: `blur${EVENT_KEY}`,
  KEYUP: `keyup${EVENT_KEY}`,
  UNQUOTED: `unquoted${EVENT_KEY}`,
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`
}

const ClassName = {
  HIDE: 'd-none',
  CONTAINER: 'emojiarea-container form-control',
  QUOTED: 'quoted',
  QUOTE: 'emojiarea-quote message-quote',
  QUOTECLOSE: 'emojiarea-quote-close icon-close',
  QUOTENAME: 'message-user',
  QUOTETEXT: 'message-text',
  WRAPPER: 'emojiarea-wrapper',
  EDITOR: 'emojiarea-editor',
  MENU: 'emojiarea-menu',
  MENUITEMS: 'emojiarea-menu-items',
  COUNTER: 'emojiarea-counter',
  TOGGLE: 'emojiarea-toggle'
}

const Selector = {
  ELEMENT: '.emojiarea-el'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class EmojiArea {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)
    this._icons = this._config.icons
    this._codeImages = this._config.shortcodes
    this._maxLength = this._config.maxLength
    this._autogrow = this._config.autogrow
    this._hasCounter = this._config.wordcount
    this._timerId = undefined
    this._isQuoted = false
    this._buildElements()
    this._setListeners()
    Data.setData(element, DATA_KEY, this)
    EventHandler.trigger(element, Event.READY)
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  // Public

  addQuote(name, content) {
    if (this._isQuoted) {
      this.removeQuote()
    }

    this._container.classList.add(ClassName.QUOTED)
    this._quote.classList.remove(ClassName.HIDE)
    this._quoteName.innerText = name
    this._quoteText.innerHTML = content
    this._container.setAttribute('tabindex', '-1')
    this._container.focus()
    this._container.removeAttribute('tabindex')
    // this._container.scrollIntoView()
    this._editor.focus()
    this._isQuoted = true

    return false
  }

  removeQuote(event) {
    const e = window.event || event
    e.preventDefault()

    this._container.classList.remove(ClassName.QUOTED)
    this._quote.classList.add(ClassName.HIDE)
    this._quoteName.innerText = ''
    this._quoteText.innerHTML = ''
    this._isQuoted = false
    EventHandler.trigger(this._element, Event.UNQUOTED)

    return false
  }

  clear() {
    this._editor.innerHTML = ''
    this._element.innerHTML = ''
  }

  // Utilities

  _clearSelection(selection) {
    if (selection) {
      if (selection.empty) {
        selection.empty()
      } else {
        selection.removeAllRanges()
      }
    }
  }

  _restoreSelection(savedSelection) {
    if (window.getSelection) {
      const sel = window.getSelection()
      if (this._getSelectionParentElement() !== this._editor) {
        this._clearSelection(sel)
        for (let i = 0; i < savedSelection.length; ++i) {
          sel.addRange(savedSelection[i])
        }
      }
      // if (this._getSelectionParentElement() === this._editor) {
      //   for (let i = 0; i < savedSelection.length; ++i) {
      //     sel.addRange(savedSelection[i])
      //   }
      // } else {
      //   this._placeCaretAtEnd(this._editor)
      // }
    } else if (document.selection && document.selection.createRange) {
      if (savedSelection) {
        savedSelection.select()
      }
    }
  }

  _storeSelection() {
    let ranges = []
    if (window.getSelection) {
      const sel = window.getSelection()
      if (sel.rangeCount) {
        for (let i = 0; i < sel.rangeCount; ++i) {
          ranges.push(sel.getRangeAt(i))
        }
      }
    } else {
      const sel = document.selection
      ranges = sel.type.toLowerCase() !== 'none' && sel.createRange()
    }

    return ranges
  }

  _setSelection(content) {
    if (document.createRange) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(content)
      range.collapse(false)
      this._clearSelection(selection)
      selection.addRange(range)
    } else if (document.selection) {
      const range = document.body.createTextRange()
      range.moveToElementText(content)
      range.collapse(false)
      range.select()
    }
  }

  _replaceSelection(content) {
    if (window.getSelection) {
      const sel = window.getSelection()
      const node = typeof content === 'string' ? document.createTextNode(content) : content

      if (this._getSelectionParentElement() !== this._editor) {
        this._placeCaretAtEnd(this._editor)
      }

      if (sel.getRangeAt && sel.rangeCount) {
        const range = sel.getRangeAt(0)
        range.deleteContents()
        range.insertNode(document.createTextNode(''))
        range.insertNode(node)
        range.insertNode(document.createTextNode(' '))
        range.setStart(node, 0)
        this._setSelectionAtTheEnd(node)
        // window.setTimeout(() => {
        //   this._setSelectionAtTheEnd(node)
        // }, 0)
      }
    } else if (document.selection && document.selection.createRange) {
      const range = document.selection.createRange()
      if (typeof content === 'string') {
        range.text = content
      } else {
        range.pasteHTML(content.outerHTML + ' ')
      }
    }
  }

  _setSelectionAtTheEnd(node) {
    const sel = window.getSelection()
    const range = document.createRange()
    this._clearSelection(sel)
    range.setStartAfter(node)
    range.collapse(true)
    sel.addRange(range)
  }

  _placeCaretAtEnd(el) {
    el.focus()
    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange()
      range.selectNodeContents(el)
      range.collapse(false)
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange()
      textRange.moveToElementText(el)
      textRange.collapse(false)
      textRange.select()
    }
  }

  _getSelectionParentElement() {
    let parentEl = null
    if (window.getSelection) {
      const sel = window.getSelection()
      if (sel.rangeCount) {
        parentEl = sel.getRangeAt(0).commonAncestorContainer
        if (parentEl.nodeType !== 1) {
          parentEl = parentEl.parentNode
        }
      }
    } else if (document.selection && document.selection.type !== 'Control') {
      parentEl = document.selection.createRange().parentElement()
    }

    return parentEl
  }

  // Helpers

  _iconImage(icon) {
    const image = document.createElement('img')
    const title = icon.code
    let { path } = this._config
    if (path.length && path.charAt(path.length - 1) !== '/') {
      path += '/'
    }

    image.className = 'emoji'
    image.setAttribute('src', path + icon.image)
    image.setAttribute('alt', title)
    return image
  }

  _iconUrl(item) {
    const title = item.code
    let { path } = this._config
    if (path.length && path.charAt(path.length - 1) !== '/') {
      path += '/'
    }

    return `<img class="emoji" src="${path + item.image}" alt="${title}">`
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    }
    typeCheckConfig(NAME, config, DefaultType)
    return config
  }

  _buildElements() {
    const autogrowClass = this._autogrow ? ' autogrow' : ''
    const counterClass = this._hasCounter ? ' counter' : ''
    const containerClass = `${ClassName.CONTAINER}${autogrowClass}${counterClass}`
    this._container = Manipulator.createElement('div', { class: containerClass }, null, this._element.parentNode)
    this._quote = Manipulator.createElement('div', { class: ClassName.QUOTE }, null, this._container)
    this._quoteName = Manipulator.createElement('div', { class: ClassName.QUOTENAME }, null, this._quote)
    this._quoteText = Manipulator.createElement('div', { class: ClassName.QUOTETEXT }, null, this._quote)
    this._quoteClose = Manipulator.createElement('a', { class: ClassName.QUOTECLOSE, href: '#' }, null, this._quote)
    this._wrapper = Manipulator.createElement('div', { class: ClassName.WRAPPER }, null, this._container)
    this._editor = Manipulator.createElement('div', {
      class: ClassName.EDITOR,
      contenteditable: 'true',
      tabindex: '0'
    }, null, this._wrapper)
    this._menu = Manipulator.createElement('div', { class: ClassName.MENU }, null, this._container)
    this._menuitems = Manipulator.createElement('div', { class: ClassName.MENUITEMS }, null, this._menu)
    this._toggler = Manipulator.createElement('button', { class: ClassName.TOGGLE }, null, this._container)
    this._element.classList.add(ClassName.HIDE)
    this._quote.classList.add(ClassName.HIDE)
    this._container.appendChild(this._element)
    this._loadContent()
    this._makeIcons()

    if (this._hasCounter) {
      this._counter = Manipulator.createElement('div', { class: ClassName.COUNTER }, '0', this._container)
      this._wordCount()
    }
  }

  _setListeners() {
    EventHandler.on(this._toggler, Event.CLICK, event => this._toggleMenu(event))
    EventHandler.on(this._quoteClose, Event.CLICK, event => this.removeQuote(event))
    EventHandler.on(this._element, Event.FOCUS, () => this._editor.focus())
    EventHandler.on(this._editor, Event.KEYUP, () => this._saveContent())
    EventHandler.on(this._editor, Event.BLUR, () => this._saveSelection())
    EventHandler.on(this._editor, Event.FOCUS, () => this._loadSelection())
    if (this._hasCounter) {
      EventHandler.on(this._editor, Event.FOCUS, () => this._startCount())
      EventHandler.on(this._editor, Event.BLUR, () => this._stopCount())
    }
  }

  _startCount() {
    let lastValue = this._editor.innerHTML
    this._timerId = setInterval(() => {
      const currentValue = this._editor.innerText

      if (currentValue.length !== lastValue.length) {
        this._showCount()
        lastValue = currentValue
      }

      if (currentValue.length > 400) {
        this._editor.innerHTML = this._safeTruncate()
        this._placeCaretAtEnd(this._editor)
      }
    }, 10)
  }

  _stopCount() {
    clearInterval(this._timerId)
  }

  _showCount() {
    const content = this._convertSpaces(this._editor.innerHTML)
    const text = content.replace(/<[^>]+>/g, '')
    const difference = this._maxLength - text.length
    this._counter.innerHTML = Math.max(0, difference)
  }

  _toggleMenu(event) {
    event.preventDefault()
    this._menuitems.classList.toggle('open')
  }

  _stripTags(html) {
    const tmp = document.createElement('div')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText
  }

  _safeTruncate() {
    const element = this._editor
    const output = document.createElement('div')
    const nodes = element.childNodes
    const max = this._maxLength
    let current = 0
    let i = 0

    while (i < nodes.length && max >= current) {
      const node = nodes[i]
      if (node.parentNode === element) {
        // Text
        if (node.nodeType === 3) {
          let content = node.textContent
          if (max - (current + content.length) > 0) {
            output.innerHTML += content
          } else {
            content = content.substr(0, max - current)
            output.innerHTML += content
          }

          current += content.length
        }

        // Element
        if (node.nodeType === 1) {
          if (node.nodeName === 'BR' || node.nodeName === 'IMG') {
            output.innerHTML += node.outerHTML
          } else {
            const content = this._stripTags(node.innerHTML)
            output.innerHTML += content
            current += content.length
          }
        }
      }

      i++
    }

    return output.innerHTML
  }

  _convertSpaces(content) {
    return content.split('&nbsp;').join(' ')
  }

  _wordCount() {
    const content = this._convertSpaces(this._editor.innerHTML)
    const text = content.replace(/<[^>]+>/g, '')
    const length = this._maxLength - text.length
    const difference = Math.max(0, length)

    if (text.length >= this._maxLength) {
      const content = this._safeTruncate()
      this._editor.innerHTML = content
      this._element.innerHTML = content
      this._setSelection(this._editor)
    }

    this._counter.innerHTML = difference
  }

  _saveSelection() {
    this._selection = this._storeSelection()
  }

  _loadSelection() {
    if (this._selection && this._getSelectionParentElement() === this._editor) {
      this._restoreSelection(this._selection)
    } else {
      this._placeCaretAtEnd(this._editor)
    }
  }

  _makeIcons() {
    this._icons.forEach(item => {
      const button = document.createElement('button')
      const icon = this._iconImage(item)
      button.className = 'emoji-button'
      button.appendChild(icon)
      EventHandler.on(button, Event.CLICK, event => {
        event.preventDefault()
        this._insertIcon(item)
      })
      this._menuitems.appendChild(button)
    })
  }

  _insertIcon(item) {
    const icon = this._iconImage(item)
    this._editor.focus()
    this._loadSelection()
    this._replaceSelection(icon)
    this._saveContent()
  }

  _loadContent() {
    this._editor.innerHTML = this._codeIcons(this._element.value, 'en')
  }

  _saveContent() {
    const html = this._editor.innerHTML
    this._element.innerHTML = this._codeImages ? this._codeIcons(html, 'de') : html
  }

  _codeIcons(content, way) {
    return this._icons.reduce((html, item) => {
      const src = way === 'en' ? `:${item.code}:` : this._iconUrl(item)
      const dest = way === 'en' ? this._iconUrl(item) : `:${item.code}:`
      return html.split(src).join(dest)
    }, content)
  }

  // Static

  static _emojiarealInterface(element, config) {
    let data = Data.getData(element, DATA_KEY)
    let _config = {
      ...Default,
      ...Manipulator.getDataAttributes(element)
    }

    if (typeof config === 'object') {
      _config = {
        ..._config,
        ...config
      }
    }

    if (!data) {
      data = new EmojiArea(element, _config)
    }

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static _jQueryInterface(config) {
    return this.each(function () {
      EmojiArea._emojiarealInterface(this, config)
    })
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, Event.LOAD_DATA_API, () => {
  const areas = makeArray(SelectorEngine.find(Selector.ELEMENT))
  for (let i = 0, len = areas.length; i < len; i++) {
    const itemData = Data.getData(areas[i], DATA_KEY)
    EmojiArea._emojiarealInterface(areas[i], itemData)
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

if (typeof $ !== 'undefined') {
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  $.fn[NAME] = EmojiArea._jQueryInterface
  $.fn[NAME].Constructor = EmojiArea
  $.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return EmojiArea._jQueryInterface
  }
}

export default EmojiArea
