/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.3.1): modal.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

import {
  jQuery as $,
  makeArray,
  typeCheckConfig
} from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'multiselect'
const VERSION = '4.3.1'
const DATA_KEY = 'bs.multiselect'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'
const ESCAPE_KEYCODE = 27 // KeyboardEvent.which value for Escape (Esc) key

const Default = {
  opened: false,
  static: false,
  multiple: false,
  search: false,
  label: false,
  placeholder: false,
  required: false,
  disabled: false,
  all: false,
  max: false,
  rel: 0
}

const DefaultType = {
  opened: 'boolean',
  static: 'boolean',
  multiple: 'boolean',
  search: 'boolean',
  label: '(boolean|string)',
  placeholder: '(boolean|string)',
  required: 'boolean',
  disabled: 'boolean',
  all: '(boolean|string)',
  max: '(number|boolean)',
  rel: 'number'
}

const Event = {
  READY: `ready${EVENT_KEY}`,
  CLICK: `click${EVENT_KEY}`,
  MOUSEDOWN: `mousedown${EVENT_KEY}`,
  // MOUSEUP: `mouseup${EVENT_KEY}`,
  FOCUS: `focus${EVENT_KEY}`,
  SELECTED: `selected${EVENT_KEY}`,
  KEYUP: `keyup${EVENT_KEY}`,
  KEYDOWN: `keydown${EVENT_KEY}`,
  SEARCH: 'search',
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`
}

const ClassName = {
  OPEN: 'open',
  SELECTED: 'selected',
  STATIC: 'static',
  MULTIPLE: 'multiple',
  DISABLED: 'disabled',
  DROPDOWN: 'multiselect pristine no-value',
  VALUE: 'multiselect-value',
  LABEL: 'multiselect-label',
  SEARCH: 'multiselect-search',
  SEARCH_INPUT: 'multiselect-search-input',
  OPTIONS: 'multiselect-options',
  OPTION: 'option',
  ALL: 'option all',
  FOOTER: 'multiselect-button',
  GROUP: 'form-group',
  BUTTON: 'btn btn-purple btn-sm',
  NOVALUE: 'no-value',
  PRISTINE: 'pristine',
  INVALID: 'is-invalid',
  ERROR: 'form-message',
  HIDE: 'd-none'
}

const Attribute = {
  SELECTED: 'selected'
}

const Selector = {
  ELEMENT: '.multiselect-el'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Multiselect {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)
    this._isInitialised = false
    this._isOpened = this._config.opened
    this._isStatic = this._config.static
    this._isMultiple = this._config.multiple
    this._isSearchable = this._config.search
    this._isDisabled = this._config.disabled
    this._max = this._config.max
    this._hasAll = this._config.all
    this._options = []
    this._visibleOptions = []
    this._value = []
    this._getOptions()

    this._elDropdown = null
    this._elCurrentValue = null
    this._elLabel = null
    this._elSearch = null
    this._elSearchInput = null
    this._elOptions = null
    this._elOptionAll = null
    this._elFooter = null
    this._elButton = null

    this._searchOptions = {
      class: ClassName.SEARCH_INPUT,
      type: 'search',
      autocomplete: 'off',
      autocapitalize: 'off',
      spellcheck: 'false',
      readonly: 'true',
      placeholder: 'Enter keyword'
    }
    this._all = {
      text: this._config.all,
      selected: false
    }
    this._elGroup = SelectorEngine.closest(this._element, `.${ClassName.GROUP}`)
    this._elError = SelectorEngine.closest(this._element, `.${ClassName.ERROR}`)
    this._groupError = SelectorEngine.closest(this._element, `.${ClassName.GROUP}.${ClassName.INVALID}`)

    this._buildElements()
    this._setListeners()
    this._renderOptions()
    this._updatePlaceholder()
    Data.setData(element, DATA_KEY, this)
    EventHandler.trigger(element, Event.READY)
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  // Public

  toggle(event) {
    return this._isOpened ? this.close(event) : this.open(event)
  }

  open(event) {
    if (event) {
      event.preventDefault()
    }

    if (this._isOpened || this._isDisabled) {
      return
    }

    this._isOpened = true
    this._elDropdown.classList.add(ClassName.OPEN)
    this._elDropdown.classList.remove(ClassName.PRISTINE)
    if (this._elGroup && this._elGroup.classList.contains(ClassName.INVALID)) {
      this._elGroup.classList.remove(ClassName.INVALID)
    }

    if (this._elSearchInput) {
      this._elSearchInput.readOnly = false
    }
  }

  close(event) {
    if (event) {
      event.preventDefault()
    }

    this._isOpened = false
    this._elDropdown.classList.remove(ClassName.OPEN)
  }

  update() {
    this._getOptions()
    this._renderOptions()
  }

  enable() {
    this._isDisabled = false
    this._elDropdown.classList.remove(ClassName.DISABLED)
  }

  disable() {
    this._isDisabled = true
    this._elDropdown.classList.add(ClassName.DISABLED)
  }

  // PRIVATE

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    }
    typeCheckConfig(NAME, config, DefaultType)
    return config
  }

  // Mount all options into DOM
  _renderOptions(options, input = null) {
    if (this._options.length) {
      this._clearOptionList()
    }

    this._visibleOptions = options && input.length ? options : this._options
    this._visibleOptions.forEach(item => this._renderOption(item, input))

    if (this._max && this._max > 0 && this._value.length >= this._max) {
      this._elDropdown.classList.add('max-items')
    }

    if (this._isMultiple) {
      this._toggleNoValue()
    }
  }

  // Mount current option into DOM
  _renderOption(option, input = null) {
    const { value, text, selected, disabled } = option
    let output = text

    if (input) {
      const regexp = new RegExp(input, 'gi')

      output = text.replace(regexp, '<span class="match">$&</span>')
    }

    const elOption = Manipulator.createElement('div', { class: ClassName.OPTION, 'data-value': value, tabindex: 0 }, output, this._elOptions)

    if (selected) {
      elOption.classList.add(ClassName.SELECTED)
    }

    if (disabled) {
      elOption.classList.add(ClassName.DISABLED)
    }

    if (value === '') {
      elOption.style.display = 'none'
    }

    EventHandler.on(elOption, Event.CLICK, event => this._clickOption(event, option))
  }

  // Remove all rendered options from container
  _clearOptionList() {
    if (!this._elOptions.firstChild) {
      return
    }

    while (this._elOptions.lastChild && this._elOptions.lastChild !== this._elOptionAll) {
      this._elOptions.removeChild(this._elOptions.lastChild)
    }
  }

  // Handle option element click
  _clickOption(event, option) {
    if (this._isMultiple) {
      this._toggleOption(option)
    } else {
      this._options.forEach(item => {
        if (item === option) {
          this._checkOption(item)
        } else {
          this._uncheckOption(item)
        }
      })
      this.close(event)
    }

    this._updateMultiselect()
  }

  // Toggle option
  _toggleOption(option) {
    if (this._value.indexOf(option.value) < 0) {
      this._checkOption(option)
    } else {
      this._uncheckOption(option)
    }

    if (this._isMultiple) {
      this._toggleNoValue()
    }
  }

  _toggleNoValue() {
    if (this._value.length > 0) {
      this._elDropdown.classList.remove(ClassName.NOVALUE)
    } else {
      this._elDropdown.classList.add(ClassName.NOVALUE)
    }
  }

  // Check option
  _checkOption(option) {
    if (this._config.rel > 0 && this._value.length >= this._config.rel) {
      return false
    }

    if (this._elDropdown.classList.contains('max-items')) {
      return false
    }

    const optionIndex = this._value.indexOf(option.value)
    const options = makeArray(this._elOptions.children)
    const elOption = options.filter(item => item.getAttribute('data-value') === option.value)[0]

    if (optionIndex < 0) {
      this._value.push(option.value)
    }

    if (this._hasAll && this._value.length === this._options.length) {
      this._elOptionAll.classList.add(ClassName.SELECTED)
    }

    elOption.classList.add(ClassName.SELECTED)
    this._updateOption(option, true)

    if (this._max && this._max > 0 && this._value.length >= this._max) {
      this._elDropdown.classList.add('max-items')
    }
  }

  // Uncheck option
  _uncheckOption(option) {
    const optionIndex = this._value.indexOf(option.value)
    const options = makeArray(this._elOptions.children)
    const elOption = options.filter(item => item.getAttribute('data-value') === option.value)[0]

    if (optionIndex > -1) {
      this._value.splice(optionIndex, 1)
    }

    if (this._hasAll && this._value.length < this._options.length) {
      this._elOptionAll.classList.remove(ClassName.SELECTED)
    }

    if (elOption) {
      elOption.classList.remove(ClassName.SELECTED)
    }

    this._updateOption(option, false)

    if (this._max && this._max > 0 && this._value.length < this._max) {
      this._elDropdown.classList.remove('max-items')
    }
  }

  // Toggle all options
  _toggleAllOptions() {
    if (this._elOptions.length === 0) {
      return
    }

    if (this._value.length < this._options.length) {
      if (this._max && this._visibleOptions.length > this._max) {
        this._visibleOptions = this._visibleOptions.slice(0, this._max)
      }

      this._visibleOptions.forEach(item => this._checkOption(item))
    } else {
      this._visibleOptions.forEach(item => this._uncheckOption(item))
    }

    if (this._isMultiple) {
      this._toggleNoValue()
    }

    this._updateMultiselect()
  }

  // Update the selected state in inner option
  _updateOption(option, state) {
    const stateOption = this._options.filter(item => item === option)

    stateOption[0].selected = state
  }

  // Update multiselect and emit event
  _updateMultiselect() {
    this._updatePlaceholder()
    this._setNativeValue()
    EventHandler.trigger(this._element, Event.SELECTED, {
      value: this._value.toString()
    })
  }

  // Set native select value
  _setNativeValue() {
    const options = makeArray(this._element.options)

    options.forEach(option => {
      const optionIndex = this._value.indexOf(String(option.value))

      if (optionIndex < 0) {
        option.removeAttribute(Attribute.SELECTED)
      } else {
        if (!this._isMultiple) {
          this._element.value = option.value
        }

        option.setAttribute(Attribute.SELECTED, 'true')
      }
    })
  }

  // Update the displayed value
  _updatePlaceholder() {
    if (!this._config.placeholder || !this._elCurrentValue) {
      return
    }

    let value = this._config.placeholder

    if (this._value && this._value.length > 1) {
      value = `${this._value.length} selected`
    } else if (this._value && this._value.length === 1) {
      value = this._getDisplayValue(this._value.toString())
    }

    // if (value !== this._config.placeholder) {
    //   this._elDropdown.classList.remove(ClassName.PRISTINE)
    // }

    this._elCurrentValue.innerHTML = value
  }

  // Get text for the value
  _getDisplayValue(value) {
    const options = makeArray(this._options)
    const option = options.filter(item => item.value === value)[0]

    return option.text
  }

  // Search input query in options
  _handleSearch(value) {
    let results

    if (value && value.length > 0) {
      results = this._options.filter(item => {
        const option = item.text.toUpperCase()
        const query = value.toUpperCase()
        return option.indexOf(query) > -1
      })
    }

    return this._renderOptions(results, value)
  }

  // Close multiselect dropdown by hitting ESC key
  _setEscapeEvent(event) {
    if (event.which === ESCAPE_KEYCODE && this._isOpened) {
      event.preventDefault()
      this.close(event)
    }
  }

  // Close multiselect dropdown when clicked outside
  _closeOutside(event) {
    if (!this._elDropdown.contains(event.target)) {
      this.close()
    }
  }

  // Remove error message on click
  _closeError(event) {
    if (event) {
      event.preventDefault()
    }

    if (this._groupError) {
      this._groupError.classList.remove(ClassName.INVALID)
    }

    if (this._elError) {
      this._elError.style.display = 'none'
      this.open(event)
    }
  }

  // Helper functions

  get _className() {
    const name = [ClassName.DROPDOWN]

    if (this._isMultiple) {
      name.push(ClassName.MULTIPLE)
    }

    if (this._isStatic) {
      name.push(ClassName.STATIC)
    }

    if (this._isDisabled) {
      name.push(ClassName.DISABLED)
    }

    if (this._isOpened) {
      name.push(ClassName.OPEN)
    }

    return name.join(' ')
  }

  _buildElements() {
    if (this._isInitialised || this._element.classList.contains(ClassName.HIDE)) {
      return
    }

    this._element.classList.add(ClassName.HIDE)
    this._elDropdown = Manipulator.createElement('div', { class: this._className }, null, this._element, true)
    this._elCurrentValue = Manipulator.createElement('div', { class: ClassName.VALUE, tabindex: 0 }, this._config.placeholder, this._elDropdown)

    if (this._config.label) {
      this._elLabel = Manipulator.createElement('div', { class: ClassName.LABEL }, this._config.label, this._elDropdown)
    }

    if (this._isSearchable) {
      this._elSearch = Manipulator.createElement('div', { class: ClassName.SEARCH }, null, this._elDropdown)
      this._elSearchInput = Manipulator.createElement('input', this._searchOptions, null, this._elSearch)
    }

    this._elOptions = Manipulator.createElement('div', { class: ClassName.OPTIONS }, null, this._elDropdown)

    if (this._hasAll) {
      const elOptionAllClassName = this._value.length === this._options.length ? `${ClassName.ALL} ${ClassName.SELECTED}` : ClassName.ALL
      this._elOptionAll = Manipulator.createElement('div', { class: elOptionAllClassName }, this._all.text, this._elOptions)
    }

    if (this._isMultiple) {
      this._elFooter = Manipulator.createElement('div', { class: ClassName.FOOTER }, null, this._elDropdown)
      this._elButton = Manipulator.createElement('button', { class: ClassName.BUTTON, type: 'button', tabindex: 0 }, 'Selection done', this._elFooter)
    }
  }

  _setListeners() {
    EventHandler.on(document.body, Event.KEYDOWN, event => this._setEscapeEvent(event))
    EventHandler.on(this._elCurrentValue, Event.MOUSEDOWN, event => this.toggle(event))
    EventHandler.on(this._elCurrentValue, Event.FOCUS, event => this.toggle(event))

    if (this._isSearchable) {
      EventHandler.on(this._elSearchInput, Event.SEARCH, event => this._handleSearch(event.target.value))
      EventHandler.on(this._elSearchInput, Event.KEYUP, event => this._handleSearch(event.target.value))
    }

    if (this._isMultiple) {
      EventHandler.on(this._elButton, Event.CLICK, event => this.close(event) && console.log('_isMultiple, clicked _elButton'))
    }

    if (this._hasAll) {
      EventHandler.on(this._elOptionAll, Event.CLICK, () => this._toggleAllOptions())
    }

    if (this._isStatic) {
      EventHandler.on(document.body, Event.CLICK, event => this._closeOutside(event))
    }

    if (this._elError || this._groupError) {
      EventHandler.on(this._elCurrentValue, Event.MOUSEDOWN, event => this._closeError(event))
      EventHandler.on(this._elError, Event.CLICK, event => this._closeError(event))
    }
  }

  _getOptions() {
    const elOptions = makeArray(this._element.options)

    this._options = elOptions.reduce((result, item, index) => {
      if (item.defaultSelected) {
        this._value.push(item.value)
      }

      return result.concat({
        id: index,
        value: item.value,
        text: item.text,
        selected: item.defaultSelected
      })
    }, [])
  }

  // Static

  static _multiselectlInterface(element, config) {
    let data = Data.getData(element, DATA_KEY)
    let _config = {
      ...Default,
      ...Manipulator.getDataAttributes(element)
    }

    if (typeof config === 'object') {
      _config = {
        ..._config,
        ...config
      }
    }

    if (!data) {
      data = new Multiselect(element, _config)
    }

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static _jQueryInterface(config) {
    return this.each(function () {
      Multiselect._multiselectlInterface(this, config)
    })
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, Event.LOAD_DATA_API, () => {
  const selects = makeArray(SelectorEngine.find(Selector.ELEMENT))
  for (let i = 0, len = selects.length; i < len; i++) {
    const itemData = Data.getData(selects[i], DATA_KEY)
    Multiselect._multiselectlInterface(selects[i], itemData)
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

if (typeof $ !== 'undefined') {
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  $.fn[NAME] = Multiselect._jQueryInterface
  $.fn[NAME].Constructor = Multiselect
  $.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Multiselect._jQueryInterface
  }
}

export default Multiselect
