/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.3.1): dom/manipulator.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

function normalizeData(val) {
  if (val === 'true') {
    return true
  }

  if (val === 'false') {
    return false
  }

  if (val === Number(val).toString()) {
    return Number(val)
  }

  if (val === '' || val === 'null') {
    return null
  }

  return val
}

function normalizeDataKey(key) {
  return key.replace(/[A-Z]/g, chr => chr.toLowerCase())
}

const Manipulator = {
  setDataAttribute(element, key, value) {
    element.setAttribute(`data-${normalizeDataKey(key)}`, value)
  },

  removeDataAttribute(element, key) {
    element.removeAttribute(`data-${normalizeDataKey(key)}`)
  },

  getDataAttributes(element) {
    if (!element) {
      return {}
    }

    const attributes = {
      ...element.dataset
    }

    if (element.tagName === 'SELECT') {
      attributes.multiple = element.multiple
      attributes.required = element.required
      attributes.disabled = element.disabled
      attributes.opened = element.getAttribute('data-opened') || false
      attributes.label = element.getAttribute('data-label') || false
      attributes.placeholder = element.getAttribute('data-placeholder') || false
      attributes.all = element.getAttribute('data-all') || false
      attributes.static = element.getAttribute('data-static') || false
      attributes.search = Boolean(element.getAttribute('data-search'))
      attributes.maxlength = element.getAttribute('data-maxlength') || 400
      attributes.delay = element.getAttribute('data-delay') || 500
      attributes.wordcount = element.getAttribute('data-wordcount') || 500
    }

    Object.keys(attributes).forEach(key => {
      attributes[key] = normalizeData(attributes[key])
    })

    return attributes
  },

  getDataAttribute(element, key) {
    return normalizeData(element.getAttribute(`data-${normalizeDataKey(key)}`))
  },

  offset(element) {
    const rect = element.getBoundingClientRect()

    return {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft
    }
  },

  position(element) {
    return {
      top: element.offsetTop,
      left: element.offsetLeft
    }
  },

  toggleClass(element, className) {
    if (!element) {
      return
    }

    if (element.classList.contains(className)) {
      element.classList.remove(className)
    } else {
      element.classList.add(className)
    }
  },

  createElement(tag, options, content, parent, placement) {
    const el = document.createElement(tag)

    if (content) {
      if (content instanceof HTMLElement) {
        el.appendChild(content)
      } else {
        el.innerHTML = content
      }
    }

    for (const key in options) {
      if (key) {
        el.setAttribute(key, options[key])
      }
    }

    if (placement) {
      parent.parentNode.insertBefore(el, parent.nextSibling)
    } else {
      parent.appendChild(el)
    }

    return el
  }
}

export default Manipulator
