/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.3.1): password.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

import {
  jQuery as $,
  makeArray,
  typeCheckConfig
} from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'password'
const VERSION = '4.3.1'
const DATA_KEY = 'bs.password'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Event = {
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`
}

const ClassName = {
  CHECKED: 'checked'
}

const AttributeName = {
  DISABLED: 'disabled'
}

const Default = {
  delay: 500
}

const DefaultType = {
  delay: 'number'
}

const Selector = {
  FORM: '[data-policy="true"]',
  PASSWORD1: '[data-field="password1"]',
  PASSWORD2: '[data-field="password2"]',
  BUTTON: '[data-type="submit"]'
}

const RuleSet = {
  length: {
    rule: val => new RegExp(/^(?=.{6,20}$)/).test(val),
    required: true,
    valid: false
  },
  number: {
    rule: val => new RegExp(/(?=.*\d)(?=.*[a-zA-Z])/g).test(val),
    required: true,
    valid: false
  },
  special: {
    rule: val => new RegExp(/(?=.*[!@#$%^&*])/g).test(val),
    required: false,
    valid: false
  },
  case: {
    rule: val => new RegExp(/(?=.*[a-zA-Z])/g).test(val),
    required: true,
    valid: false
  },
  spaces: {
    rule: val => new RegExp(/^[\S]+$/g).test(val),
    required: true,
    valid: false
  },
  equal: {
    rule: (val1, val2) => val1 && val2 && val1 === val2,
    required: true,
    valid: false
  }
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Password {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)
    this._password = SelectorEngine.findOne(Selector.PASSWORD1, this._element)
    this._password2 = SelectorEngine.findOne(Selector.PASSWORD2, this._element)
    this._button = SelectorEngine.findOne(Selector.BUTTON, this._element)
    this._delay = this._config.delay
    this._rules = RuleSet
    this._timeout = null
    this._isDisabled = true
    this._setListeners()
    this._validationResult()
    Data.setData(element, DATA_KEY, this)
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    }

    typeCheckConfig(
      NAME,
      config,
      this.constructor.DefaultType
    )

    return config
  }

  _validate() {
    if (this._timeout) {
      clearInterval(this._timeout)
      this._timeout = null
    }

    this._timeout = setTimeout(this._validatePassword.bind(this), this._delay)
  }

  _validatePassword() {
    for (const [key, value] of Object.entries(this._rules)) {
      const el = document.getElementById(key)
      const val1 = this._password.value
      const val2 = this._password2 ? this._password2.value : undefined
      const isValid = value.rule(val1, val2)
      this._rules[key].valid = isValid

      if (el) {
        el.classList.toggle(ClassName.CHECKED, isValid)
      }
    }

    this._validationResult()
  }

  _validationResult() {
    if (!this._button) {
      return
    }

    this._isDisabled = !Object.values(this._rules).every(value => !value.required || value.valid)
    this._button.toggleAttribute(AttributeName.DISABLED, this._isDisabled)
  }

  _setListeners() {
    EventHandler.on(this._password, 'input', this._validate.bind(this))
    EventHandler.on(this._password2, 'input', this._validate.bind(this))
  }

  // Static

  static _jQueryInterface(config) {
    return this.each(function () {
      let data = Data.getData(this, DATA_KEY)
      const _config = typeof config === 'object' && config

      if (!data) {
        data = new Password(this, _config)
      }

      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError(`No method named "${config}"`)
        }

        data[config](this)
      }
    })
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, Event.LOAD_DATA_API, () => {
  makeArray(SelectorEngine.find(Selector.FORM))
    .forEach(form => new Password(form))
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 *  add .password to jQuery only if jQuery is present
 */

if (typeof $ !== 'undefined') {
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  $.fn[NAME] = Password._jQueryInterface
  $.fn[NAME].Constructor = Password
  $.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Password._jQueryInterface
  }
}

export default Password
